export default defineComponent({
  props: {
    isPaiOnly: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup() {
    var showPaiList = ref(false);
    var showNoDataText = ref(false);
    var showChangingPais = ref(false);
    return {
      showPaiList: showPaiList,
      showNoDataText: showNoDataText,
      showChangingPais: showChangingPais
    };
  }
});